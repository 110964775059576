import styled from 'styled-components'
import chevronBottom from '../../../static/icons/ic-dropdown-bottom.png'

export const FooterStyle = styled.footer`
  background-color: #fbfbfb;
  padding: 60px 0 110px 0;
  display: flex;
  margin-top: ${props => (props.noMargin ? 0 : 70)}px;
  .footer-about {
    p {
      margin: 0;
      padding: 0;
      color: #929292;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 24.3px;
    }
    &--top {
      margin: 16px 0 20px 0;
    }
    &--bottom {
      .emailLink {
        list-style: none;
        color: #929292;
        text-decoration: none;
        &:hover {
          color: #ff7c00;
        }
      }
    }
    &--logo {
      > a {
        display: block;
        width: 110px;
        > img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .footer-nav {
    &--list {
      margin: 0;
      padding: 0;
      list-style: none;
      > li {
        display: block;
        > a {
          display: flex;
          align-items: center;
          text-decoration: none;
          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          color: #929292;
          line-height: 20px;
          margin: 15px 0;
          > i {
            margin-right: 12px;
          }

          &:hover {
            color: #ff7c00;
          }
        }
      }
    }
    &--title {
      display: inline-block;
      text-decoration: none;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      color: #ff7400;
      line-height: 19px;
      margin: 7px 0;
    }
  }

  .social-media {
    img {
      display: none;
      margin-right: 12px;
    }

    a {
      &:hover {
        i {
          display: none;
        }

        img {
          display: block;
          width: 29px;
          height: 29px;
        }
      }
    }
  }

  .social-icon {
    background-size: 29px;
  }

  @media screen and (min-width: 300px) and (max-width: 767px) {
    .halojasa-info {
      flex-basis: unset;
      max-width: 100%;
    }
    .footer-nav--title {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px 0;
      border-bottom: 1px solid #ff7400;
      &.social-media {
        border-bottom: unset;
      }
    }
    .footer-nav--list {
      background-color: #eeeeee;
      > li {
        > a {
          margin: unset;
          padding: 16px;
          font-size: 14px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #929292;
          border-bottom: 1px solid #dddddd;
        }
      }
      &.social-media {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        background-color: unset;
        > li {
          display: flex;
          flex-basis: 50%;
          justify-content: center;
          flex-direction: column;
          > a {
            padding: 16px 0;
            border-bottom: unset;
          }
        }
      }
    }

    .footer-hide {
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }

    .footer-show {
      height: auto;
      opacity: 1;
      visibility: visible;
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }
  }
`

export const IconFooter = styled.i`
  display: none;

  @media screen and (min-width: 300px) and (max-width: 767px) {
    width: 18px;
    height: 18px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 18px;
    transition-delay: 0.3s;
    transition-timing-function: ease-in;
    transition-duration: 0.3s;
    transition-property: transform;
    &.bottom {
      transform: rotate(180deg);
      background-image: url(${chevronBottom});
    }
    &.up {
      transform: rotate(0deg);
      background-image: url(${chevronBottom});
    }
  }
`
