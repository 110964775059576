import React, { useState, lazy } from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Link } from 'react-router-dom'

import { FooterStyle, IconFooter } from './index.styles'
import Logo from '../../../static/images/landing-page-one/logo/logo.webp'
import twIcon from '../../../static/images/twitter.webp'
import inIcon from '../../../static/images/linkedin.webp'
import youIcon from '../../../static/images/youtube.png'
import fbIcon from '../../../static/images/facebook.webp'
import igIcon from '../../../static/images/instagram.png'
import pintIcon from '../../../static/images/pinterest.png'

const Icon = lazy(() => import('../../icons'))

const Footer = props => {
  const [isActive, setFooterShow] = useState('')
  const { noMargin } = props
  const clickFooter = (e, params) => {
    e.preventDefault()
    setFooterShow(prevState => (prevState === params ? '' : params))
  }

  if (window.location.href.indexOf('webview') > -1) return null

  return (
    <FooterStyle noMargin={noMargin}>
      <Grid>
        <Row>
          <Col xs={12} md={6} lg={4}>
            <div className="footer-about">
              <div className="footer-about--logo">
                <Link to="/">
                  <img src={Logo} alt="halojasa/logo" />
                </Link>
              </div>
              <div itemScope itemType="http://schema.org/Organization">
                <div className="footer-about--top">
                  <p itemProp="brand">PT Halo Komunikasi Sejahtera</p>
                  <p>
                    <span itemProp="streetAddress">
                      Jl. KH. Wahid Hasyim No.162, RT.1/RW.9,&nbsp;
                    </span>
                    Tanah Abang,
                  </p>
                  <p>
                    <span itemProp="addressLocality">
                      Kp. Bali, Jakarta Pusat
                    </span>
                    <span itemProp="postalCode"> 10250</span>
                  </p>
                </div>
                <div className="footer-about--bottom">
                  <p itemProp="telephone">
                    Whatsapp : +62 895-3800-66656 (Customer Care)
                  </p>
                  <p itemProp="telephone">
                    Whatsapp : +62 812-1941-4726 (Booking Call)
                  </p>
                  <p>Fast Booking only Support Jakarta Area</p>
                  <br />
                  <p itemProp="email">
                    Email :{' '}
                    <a className="emailLink" href="mailto:help@halojasa.com">
                      help[at]halojasa.com
                    </a>{' '}
                    (Komplain dan support customer/vendor)
                  </p>
                  <p itemProp="email">
                    Email :{' '}
                    <a className="emailLink" href="mailto:info@halojasa.com">
                      info[at]halojasa.com
                    </a>{' '}
                    (Informasi umum dan karir)
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={2}>
            <div
              onClick={e => clickFooter(e, 'footer-1')}
              className="footer-nav--title"
            >
              Perusahaan
              <IconFooter
                className={` ${isActive === 'footer-1' ? 'bottom' : 'up'}`}
              ></IconFooter>
            </div>
            <ul
              className={`footer-nav--list ${isActive === 'footer-1' ? 'footer-show' : 'footer-hide'
                }`}
            >
              <li>
                <Link className="about-footer" to="/bantuan/tentang-kami/">
                  Tentang Kami
                </Link>
              </li>
              <li>
                <a className="about-footer" href="/jasa/">
                  Cabang
                </a>
              </li>
              <li>
                <Link className="overview-footer" to="/overview/">
                  Overview
                </Link>
              </li>
              <li>
                <a
                  className="vendor-footer"
                  href="https://halojasa.com/blog-vendor/"
                >
                  Pelatihan
                </a>
              </li>
              <li>
                <a
                  className="vendor-footer"
                  href="https://halojasa.com/jasa/franchise-spa/"
                >
                  Mitra Usaha
                </a>
              </li>
              <li>
                <Link className="career-footer" to="/karir/all/">
                  Karir
                </Link>
              </li>
              <li>
                <a className="blog-footer" href="https://halojasa.com/blog/">
                  Blog News
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={6} lg={2}>
            <div
              onClick={e => clickFooter(e, 'footer-2')}
              className="footer-nav--title"
            >
              Layanan
              <IconFooter
                className={` ${isActive === 'footer-2' ? 'bottom' : 'up'}`}
              ></IconFooter>
            </div>
            <ul
              className={`footer-nav--list ${isActive === 'footer-2' ? 'footer-show' : 'footer-hide'
                }`}
            >
              <li>
                <Link
                  className="massage-footer"
                  to="/reflexology-massage-pijat-panggilan/"
                >
                  Halo Massage
                </Link>
              </li>
              <li>
                <Link className="auto-footer" to="/salon-mobil-pangilan/">
                  Halo Auto
                </Link>
              </li>
              <li>
                <Link className="ac-footer" to="/service-ac-jakarta/">
                  Halo Fix
                </Link>
              </li>
              <li>
                <Link className="cleaning-footer" to="/jasa-cleaning-service/">
                  Halo Clean
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={6} lg={2}>
            <div
              onClick={e => clickFooter(e, 'footer-3')}
              className="footer-nav--title"
            >
              Pusat Bantuan
              <IconFooter
                className={` ${isActive === 'footer-3' ? 'bottom' : 'up'}`}
              ></IconFooter>
            </div>
            <ul
              className={`footer-nav--list ${isActive === 'footer-3' ? 'footer-show' : 'footer-hide'
                }`}
            >
              <li>
                <Link className="help-footer" to="/bantuan/pusat-bantuan/">
                  Pusat Bantuan
                </Link>
              </li>
              <li>
                <Link className="privacy-footer" to="/bantuan/privacy/">
                  Kebijakan Privasi
                </Link>
              </li>
              <li>
                <Link className="terms-footer" to="/bantuan/syarat-umum/">
                  Syarat Umum
                </Link>
              </li>
              <li>
                <Link className="payment-footer" to="/bantuan/cara-pembayaran/">
                  Pembayaran
                </Link>
              </li>
              <li>
                <Link className="faq-footer" to="/bantuan/faq/">
                  FAQ
                </Link>
              </li>
              <li>
                <Link to="/bantuan/informasi-layanan/">Informasi Layanan</Link>
              </li>
              <li>
                <Link to="/bantuan/pengaduan-layanan/">Pengaduan Layanan</Link>
              </li>
              <li>
                <a href="https://halojasa.com/blog-vendor/cara-mendaftar-vendor-halo-jasa-2020/">
                  Cara Daftar Vendor
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={6} lg={2}>
            <div className="footer-nav--title social-media">Follow Us</div>
            <ul className="footer-nav--list social-media">
              <li>
                <a href="https://www.facebook.com/halojasacom/">
                  <Icon className="social-icon" name="facebook"></Icon>
                  <img src={fbIcon} alt="halojasa/facebook-icon" />
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://twitter.com/halojasa_id">
                  <Icon className="social-icon" name="twitter"></Icon>
                  <img src={twIcon} alt="halojasa/twitter-icon" />
                  Twitter
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/halojasaindonesia/">
                  <Icon className="social-icon" name="instagram"></Icon>
                  <img src={igIcon} alt="halojasa/instagram-icon" />
                  Instagram
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/pthalojasa-indonesia/">
                  <Icon className="social-icon" name="linkedin"></Icon>
                  <img src={inIcon} alt="halojasa/linkedin-icon" />
                  Linkedin
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCTLLGbSZgjAGi1BoAXcUf2Q/videos">
                  <Icon className="social-icon" name="youtube"></Icon>
                  <img src={youIcon} alt="halojasa/youtube-icon" />
                  Youtube
                </a>
              </li>
              <li>
                <a href="https://id.pinterest.com/pthalojasa/">
                  <Icon className="social-icon" name="pinterest"></Icon>
                  <img src={pintIcon} alt="halojasa/pinterest-icon" />
                  Pinterest
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Grid>
    </FooterStyle>
  )
}

export default Footer
